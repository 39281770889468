<template lang="pug">
	.goals.flex.justify-between.items-center
		.goals__item
			.goals__title Below Goal
			.flex
				Checkbox.goals__checkbox(
					v-for="(item, index) in goalsBelow"
					:key="`below-${index}`"
					:value="item.value"
					type="gray"
					size="sm"
					:class="item.color"
					@change="changeHandler(item, 'below')"
				)
		.goals__item
			.goals__title Above Goal
			.flex
				Checkbox.goals__checkbox(
					v-for="(item, index) in goalsAbove"
					:key="`below-${index}`"
					:value="item.value"
					type="gray"
					size="sm"
					:class="item.color"
					@change="changeHandler(item, 'above')"
				)
</template>

<script>
import Checkbox from '@/components/Elements/Checkbox.vue'

export default {
	components: {
		Checkbox,
	},
	props: {
		goalsBelowCheckbox: {
			type: Object,
			default: null,
		},
		goalsAboveCheckbox: {
			type: Object,
			default: null,
		}
	},
	data() {
		return {

		}
	},
	computed: {
		goalsBelow() {
			return [
				{
					title: 'high',
					color: 'goal-below-high',
					value: this.goalsBelowCheckbox.high
				},
				{
					title: 'medium',
					color: 'goal-below-medium',
					value: this.goalsBelowCheckbox.medium
				},
				{
					title: 'light',
					color: 'goal-below-light',
					value: this.goalsBelowCheckbox.light
				}
			]
		},
		goalsAbove() {
			return [
				{
					title: 'light',
					color: 'goal-above-light',
					value: this.goalsAboveCheckbox.light
				},
				{
					title: 'medium',
					color: 'goal-above-medium',
					value: this.goalsAboveCheckbox.medium
				},
				{
					title: 'high',
					color: 'goal-above-high',
					value: this.goalsAboveCheckbox.high
				}
			]
		},
	},
	methods: {
		changeHandler(item, type) {
			// if (type === 'below') {
			// 	this.goalsBelowCheckbox[item.title] = !item.value
			// } else {
			// 	this.goalsAboveCheckbox[item.title] = !item.value
			// }
			this.$emit('change', {item: item, type: type})
		},
	},
}
</script>

<style lang="scss" scoped>
.goals {
	&__item {
		margin-left: 8px;
	}
	&__title {
		margin: 4px 0;
	}
	&__checkbox {
		&.goal-above-high {
			::v-deep .checkbox__icon{
				background-color: color(goal-above-high);
			}
		}
		&.goal-above-medium {
			::v-deep .checkbox__icon {
				background-color: color(goal-above-medium);
			}
		}
		&.goal-above-light {
			::v-deep .checkbox__icon {
				background-color: color(goal-above-light);
			}
		}
		&.goal-below-light {
			::v-deep .checkbox__icon {
				background-color: color(goal-below-light);
			}
		}
		&.goal-below-medium {
			::v-deep .checkbox__icon {
				background-color: color(goal-below-medium);
			}
		}
		&.goal-below-high {
			::v-deep .checkbox__icon {
				background-color: color(goal-below-high);
			}
		}
	}
}
</style>
