const YANDEX_POKUPKI_APP = 'Yandex.pokupki_APP'
const YANDEX_MARKET_APP = 'Yandex.market_APP'
const PRODUCTS = 'products'
const STORES = 'stores'

const renameYandex = (arr) => {
    const changedArray = [...arr]
    const index = changedArray.findIndex((item) => item.name === YANDEX_POKUPKI_APP)
    if (index !== -1) {
        changedArray[index].name = YANDEX_MARKET_APP
    }
    return changedArray
}

const changeArray = (arr) => {
    const result = Object.values(arr).map((item) => {
        if (item.store.name === YANDEX_POKUPKI_APP) {
            item.store.name = YANDEX_MARKET_APP
        }
        return item
    })
    return result
}

const renameYandexInObject = (obj) => {
    const result = {...obj}
    
    const index = Object.entries(result).find(([key, value]) => {
        if (value.name !== YANDEX_POKUPKI_APP) return
        return key
    })
    if (index) {
        result[index[0]].name = YANDEX_MARKET_APP
    }
    return result
}

const renameYandexByName = (obj) => {
    const changedArray = {...obj}
    changeArray(changedArray.stores)

    if (Object.prototype.hasOwnProperty.call(changedArray, PRODUCTS)) {
        changeArray(changedArray[PRODUCTS])
    }
    return changedArray
}

const renameYandexForContentIntegrity = (obj) => {
    const changedArray = {...obj}

    const changedProducts = obj[PRODUCTS].map((item) => {
        if (item.storeName === YANDEX_POKUPKI_APP) {
            item.storeName = YANDEX_MARKET_APP
        }
        return item
    })

    const changedStores = obj[STORES].map((item) => {
        if (item.name === YANDEX_POKUPKI_APP) {
            item.name = YANDEX_MARKET_APP
        }
        return item
    })
    changedArray[STORES] = changedStores
    changedArray[PRODUCTS] = changedProducts

    return changedArray
}

const renameYandexIA = (obj) => {
    const PRODUCT_IMAGE = 'productImageScoreDetailed'
    const IMAGE_SCORE = 'imageScoreCountGroupByOnlineStore'

    Object.values(obj[PRODUCT_IMAGE]).forEach((item) => {
        if(item.storeName === YANDEX_POKUPKI_APP) {
            item.storeName = YANDEX_MARKET_APP
        }
    })

    Object.values(obj[IMAGE_SCORE]).forEach((item) => {
        if(item.storeName === YANDEX_POKUPKI_APP) {
            item.storeName = YANDEX_MARKET_APP
        }
    })

    return obj
}

export {
    renameYandex,
    renameYandexByName,
    renameYandexInObject,
    renameYandexIA,
    renameYandexForContentIntegrity
}