<template lang="pug">
    .is-loading
        .skeleton
            template(v-for="index in totalLines")
                PuSkeleton(:width="width" :height="height" :color="color")
                .mt-16(v-if="index !== totalLines")

</template>

<script>
export default {
    name: 'CommonSkeleton',
    props: {
        width: {
            type: String,
            default: '100%',
        },
        height: {
            type: String,
            default: '25px',
        },
        color: {
            type: String,
            default: '#E0E0E0',
        },
        totalLines: {
            type: Number,
            default: 4,
        },
    },
}
</script>

<style lang="scss" scoped>
.is-loading {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
}
.skeleton {
	width: 100%;
	padding: 20px;
}
</style>